import React from "react";
import ReportIcon from "../../../assets/icons/icon/subscriptions.svg";

export const SubmitionEnterprise = ({ name }) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div style={{ margin: "20px 0" }}>
          <h2
            style={{ fontSize: "20px", color: "#7a51ae", fontWeight: "normal" }}
          >
            {name}
          </h2>
        </div>

        <div style={{ margin: "20px 0" }}>
          {/* <img src={ReportIcon} alt="report" /> */}
        </div>
        <div style={{ margin: "20px 0" }}>
          <p style={{ fontSize: "32px" }}>Congratulations!</p>
        </div>
        <div style={{ margin: "10px 0" }}>
          <p style={{ fontSize: "18px", fontWeight: "normal" }}>
            You have successfully completed the assessment.
          </p>
        </div>
        <div style={{ margin: "10px 0" }}>
          <p style={{ fontSize: "18px", fontWeight: "normal" }}>
            Assessment Results has been shared to your email.
          </p>
        </div>
        <div style={{ margin: "10px 0" }}>
          <p style={{ fontSize: "18px", fontWeight: "normal" }}>
            Your HR team will be in touch with you for next steps.
          </p>
        </div>
        <div style={{ margin: "10px 0" }}>
          <p style={{ fontSize: "18px", fontWeight: "normal" }}>
            You can now close the browser.
          </p>
        </div>
      </div>
    </>
  );
};
